exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-templates-page-attractions-js": () => import("./../../../src/templates/page-attractions.js" /* webpackChunkName: "component---src-templates-page-attractions-js" */),
  "component---src-templates-page-directions-js": () => import("./../../../src/templates/page-directions.js" /* webpackChunkName: "component---src-templates-page-directions-js" */),
  "component---src-templates-page-group-visits-js": () => import("./../../../src/templates/page-group-visits.js" /* webpackChunkName: "component---src-templates-page-group-visits-js" */),
  "component---src-templates-page-information-js": () => import("./../../../src/templates/page-information.js" /* webpackChunkName: "component---src-templates-page-information-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/page-news.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-news-single-js": () => import("./../../../src/templates/page-news-single.js" /* webpackChunkName: "component---src-templates-page-news-single-js" */),
  "component---src-templates-page-opening-hours-js": () => import("./../../../src/templates/page-opening-hours.js" /* webpackChunkName: "component---src-templates-page-opening-hours-js" */),
  "component---src-templates-page-prices-js": () => import("./../../../src/templates/page-prices.js" /* webpackChunkName: "component---src-templates-page-prices-js" */),
  "component---src-templates-page-reservation-js": () => import("./../../../src/templates/page-reservation.js" /* webpackChunkName: "component---src-templates-page-reservation-js" */)
}

